console.info(
  '%ccoded by DOCONO%c 🤘🏻 https://docono.io\n',
  'padding: 5px; background: #ea4e66; color: #ffffff; font-size: 11px;',
  ''
);

// Foundation
$(document).foundation();


var zahlenmacher = {
    slide: function (self, icon) {
        // Remove all
        $('.svgicon').removeClass('is-active');
        $('.slider_content > div.is-active').fadeOut(150, function () {
            $(this).removeClass('is-active');
            // Add Active Classes to SVG
            self.addClass('is-active');
            $('#' + icon + '').addClass('is-active');
            $('#use').delay(400).attr('xlink:href', '#' + icon);

            // Content Animation
            $('.' + icon + '').delay(400).fadeIn(300).addClass('is-active');
        });
    }
};

window.onunload = function () {}

$(document).ready(function () {

    // Menu Trigger
    $('#MenuTrigger').click(function () {
        $('body').toggleClass('menu-active');
        $(this).toggleClass('is-active');
        $('#SubMenuHide').toggleClass('not_shown');
    });

    // // SVG Slider on Click
    $('#slideThumbs .svgicon').click(function () {
        if ($(this).data('icon')){
            zahlenmacher.slide($(this), $(this).data('icon'));
        }
    });

    // Automate every 8 Seconds
    setInterval(function () {
        var nextIcon = $('.svgicon.is-active').next('.svgicon')

        if(nextIcon.length <= 0)
            nextIcon = $('.svgicon:first-child')

        nextIcon.trigger('click');
    }, 8000);

    // reset transition state
    $('.site_main').removeClass('fadeSiteOut');
    $('.navigation_overlay').removeClass('expanded');
    $('.navigation_overlay').removeClass('slideOut');


    // Page Transition
    $('a').each(function () {
        if($(this).attr('target') === '_blank')
            return;

        if (location.hostname === this.hostname || !this.hostname.length) {
            var link = $(this).attr("href");

            if (link.match("^#")) {
                $(this).click(function () {
                    var target = $(link);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top - 70
                        }, 1000);
                        return false;
                    }
                });
            } else if (link.match("^mailto") || link.match("^tel")) {
                // Act as normal
            } else {

                $(this).click(function (e) {
                    e.preventDefault();
                    $('.site_main').addClass('fadeSiteOut');

                    if ($('.navigation_overlay').hasClass('expanded')) {
                        $('.navigation_overlay').addClass('slideOut');
                    }
                    setTimeout(function () {
                        window.location = link;
                    }, 500);
                });
            }

        }
    });


    // Resize and Scroll trough Areas visibility
    // $(window).on('scroll', function () {
    //     $('.area').each(function () {
    //         var visible = $(this).visible(true, true);
    //         if (visible) {
    //             $(this).addClass('visible');
    //         }
    //     });
    // });

});
